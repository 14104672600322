<template>
  <div class="excel-load flex flex-column card-header-divider">
    <div
        v-if="!loadStarted"
        class="excel-load__label display--flex justify-content-center"
    >
      <div class="excel-load__block">
        <div>
          Выберите файл с сотрудниками (<span class="excel-load__example"
        ><a :href="url">Скачать пример</a></span
        >)
        </div>
        <custom-file-loader
            :showFileName="true"
            name="excel-loader"
            @loading="loadingAttempt"
            accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
      </div>
      <div :class="{'password-block': groupId}" class="excel-load__block">
        <div v-if="!groupId">
          <div>Выберите группу</div>
          <custom-select-2
              name="excel-group"
              v-model="selectedGroup"
              :options="groupsOptions"
              :placeholder="'Без группы'"
          />
        </div>
        <div class="password_change_block">
          <Popper class="password_change_popper" hover arrow :content="'Сменить пароль после первого входа'">
            <div class="password_change_icon">
              <img src="@/assets/images/icons/password_change.svg" alt="Сменить пароль после первого входа"/>
            </div>
          </Popper>
          <div class="password_change_checkbox">
            <custom-checkbox
                v-model="changePassword"
            />
          </div>
        </div>
      </div>
      <div class="excel-load__block">
        <div>Нажмите на кнопку ниже и ожидайте</div>
        <button @click="load" class="btn btn-accent mr-10">Загрузить</button>
      </div>
    </div>

    <preloader v-if="loadStarted"/>
  </div>
</template>

<script>
import {ref} from "@vue/reactivity";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import CustomFileLoader from "@/components/Forms/Fields/CustomFileLoader.vue";
import {computed, onBeforeMount} from "@vue/runtime-core";
import {useStore} from "vuex";
import {notify} from "@kyvg/vue3-notification";
import {useRoute, useRouter} from "vue-router";
import Preloader from "@/components/Technical/Preloader.vue";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";

export default {
  components: {CustomCheckbox, CustomSelect2, CustomFileLoader, Preloader},
  name: "excel-load-row",
  props: {
    groupId: {
      type: Number,
      required: false,
    }
  },
  setup(props) {
    const store = useStore(),
        router = useRouter(),
        route = useRoute(),
        url = `${process.env.VUE_APP_BACK}/storage/user_download_example.xlsx`;
    const hideOptions = ref(true),
        groups = computed(() => store.state.group.groups),
        groupsOptions = computed(() => {
          let options = [{id: 0, text: "Без группы"}];
          return options.concat(groups.value ? groups.value.map((group) => {
            return {id: group.id, text: group.name};
          }) : []);
        }),
        selectedGroup = ref(null);

    let selectedFile = null;
    let loadStarted = ref(false);
    let changePassword = ref(false);

    onBeforeMount(() => {
      if (route.path !== "/group") store.dispatch("group/getGroups");
    });

    const loadingAttempt = (files) => {
      selectedFile = files[0];
    };

    const load = () => {
      if (!selectedFile) {
        notify({
          type: "warning",
          title: "Необходимо выбрать файл",
        });
      } else {
        loadStarted.value = true;

        if (selectedGroup.value === '0') {
          selectedGroup.value = null;
        }

        store
            .dispatch("group/loadGroupFromExcel", {
              file: selectedFile,
              group_id: (selectedGroup.value || props.groupId) ?? null,
              password_change: changePassword.value,
            })
            .then(() => {
              router.push("/excel");
            }).catch(() => {
              loadStarted.value = false;
            });
      }
    };

    return {
      hideOptions,
      groups,
      groupsOptions,
      selectedGroup,
      selectedFile,
      loadingAttempt,
      load,
      loadStarted,
      url,
      changePassword
    };
  },
};
</script>

<style lang="scss" scoped>

.password_change_block {
  display: flex;
  column-gap: 15px;
  justify-content: center;
  align-items: center;
  align-content: center;
  vertical-align: center;
}

.password_change_popper {
  display: block;
}

.password_change_icon img {
  width: 40px;
  height: 40px;
}

.password_change_checkbox {
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.password-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.excel-load {
  &__block {
    width: 100%;
    padding: 15px;
    border: 2px solid #ebebeb;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    .btn-accent {
      margin-top: 10px;
    }
  }

  &__block:not(:last-child) {
    border-right: none;
  }

  &__label {
    margin-bottom: 20px;
    font-weight: 400;

    @media (min-width: 974px) {
      padding-left: 10px;
    }
  }

  @media (max-width: 1200px) {
    &__label {
      flex-direction: column;
      align-items: center;
    }
    &__block {
      width: 100%;
    }
    &__block:not(:last-child) {
      border-right: 2px solid #ebebeb;
      border-bottom: none;
    }
  }

  &__row {
    .form-group:first-child {
      min-width: 50%;
    }

    .form-group {
      max-width: 50%;
    }
  }

  // .excel-load__options

  &__options {
  }

  // .excel-load__example

  &__example {
    a {
      color: var(--main-color);
      font-weight: 300;
      font-size: 16px;
      cursor: pointer;
      padding: 6px;

      &:hover {
        color: var(--hover-color);
        border-color: var(--hover-color);
      }
    }
  }

  @media (max-width: 1024px) {
    &__row {
      flex-direction: column;
      align-items: flex-start;

      .form-group,
      .form-group:first-child {
        min-width: 100%;
        margin-bottom: 20px;
      }

      .form-group:not(:first-child) {
        align-items: center;
      }
    }

    .form-group > div {
      padding: 0;
    }

    &__example {
      margin-top: 15px;
    }
  }
}

@media (max-width: 767px) {
  .password_change_block {
    margin-top: 15px;
  }
}
</style>
